
import {defineComponent} from 'vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useProjectList from './projectList';
import CreateEditProjectDialog from '@/components/project/CreateEditProjectDialog.vue';

export default defineComponent({
  name: 'ProjectList',
  components: {
    ListLayout,
    CreateEditProjectDialog,
  },
  setup() {
    const {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    } = useProjectList();

    return {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    };
  },
});
