
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import useProject from '@/components/project/project';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import TagInput from '@/components/input/TagInput.vue';

export default defineComponent({
  name: 'ProjectForm',
  components: {TagInput, FormItem, Form},
  setup() {
    // store
    const store = useStore();

    return {
      ...useProject(store),
    };
  },
});
